import Vue from "vue";
import SpinnerMixin from "@/mixins/spinner";
import AppDropDownListOwnerType from "@/components/Inputs/AppDropDownListOwnerType.vue";
export default Vue.extend({
    components: {
        "app-dropdownlist-owner-type": AppDropDownListOwnerType
    },
    mixins: [SpinnerMixin],
    data() {
        return {
            ownerType: null
        };
    },
    methods: {
        search() {
            this.$emit("search", {
                ownerType: this.ownerType
            });
        }
    }
});
